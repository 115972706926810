/* CSS for the teaching page */

/* vertical menu of courses taught */
#teaching-menu {
	width: 100%;
}

.teaching-submenu {
	font-size:80%;
}

.teaching-problem-solution h5 {
	color:#aaa;
}

.teaching-problem-solution .column div {
	border: 1px solid #eee;
	background-color:rgb(220,200,220);
	padding: 1em;
	
}

.teaching-problem-solution {
	background-color:white;
	padding: 2em;
}
