/* CSS for the digital ecosystem page */

/* resume row with small icons */

.resume-row {
    padding-top:12px;
    padding-bottom: 24px;
}

.resume-col-left {
    text-align: right;
    height: 36px;
    padding-right: 36px;
    background: transparent url(/assets/img/pdf-icon-28x36.png) center right no-repeat;

}

.resume-col-right {
    text-align:left;
    height:36px;
    padding-left: 36px;
    background: transparent url(/assets/img/pdf-icon-28x36.png) center left no-repeat;
}

.resume-col span {
    display: block;
    font-size: 60%;
}



