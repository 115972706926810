/* Common Plyojump styles */

/* styles shared across multiple pages */

$menu-background: #353535;
$menu-highlight: #444;
$transparent: transparent;

$pale-rose: #f7f0ed;
$light-rose: #f9e2d9;
$solid-rose: #fcc6c8;

$stripe-alpha: 0.0380392;

@mixin rose-gradient () {

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f7f0ed+50,f9e2d9+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left,  #ffffff 0%, $pale-rose 50%, $light-rose 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #ffffff 0%,$pale-rose 50%,$light-rose 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #ffffff 0%,$pale-rose 50%,$light-rose 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='$light-rose',GradientType=1 ); /* IE6-9 */

}

@mixin stripes () {
  background: -webkit-linear-gradient(45deg, rgba(0,0,0,$stripe-alpha) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,$stripe-alpha) 75%, rgba(0,0,0,$stripe-alpha) 0), -webkit-linear-gradient(45deg, rgba(0,0,0,$stripe-alpha) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,$stripe-alpha) 75%, rgba(0,0,0,$stripe-alpha) 0), rgb(255, 255, 255);
  background: -moz-linear-gradient(45deg, rgba(0,0,0,$stripe-alpha) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,$stripe-alpha) 75%, rgba(0,0,0,$stripe-alpha) 0), -moz-linear-gradient(45deg, rgba(0,0,0,$stripe-alpha) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,$stripe-alpha) 75%, rgba(0,0,0,$stripe-alpha) 0), rgb(255, 255, 255);
  background: linear-gradient(45deg, rgba(0,0,0,$stripe-alpha) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,$stripe-alpha) 75%, rgba(0,0,0,$stripe-alpha) 0), linear-gradient(45deg, rgba(0,0,0,$stripe-alpha) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,$stripe-alpha) 75%, rgba(0,0,0,$stripe-alpha) 0), rgb(255, 255, 255);
  background-position: 0 0, 40px 40px;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: 80px 80px;
  background-size: 80px 80px;
}


/* glow on secondary headings */
@mixin header-glow () {
    text-shadow: -1px 1px 24px #ffc, 1px -1px 24px #fff;
}


/* page gradient */
body {

    height: auto;
    //background-color: #ddd;

     @include rose-gradient;

     //background-image: url('../img/pattern-01.png');

    //@include stripes;

}


/* some basic styles */
.invisible {

    display: none;

}

.plus-minus {

    font-size:120%;
    margin-left:0.5%!important;
    margin-right:2.5%!important; /* TODO: RATIONALIZE THESE!!!!! */
    padding: 4px;
    border: 1px solid #ddd;
    background-color:#333;

}

.plus-minus a {

    color: #c7e7ff;

}

.page-text-highlight {

    background-color:#f9ffbc;padding:0.5em;

}

.form-response-highlight {

    border-radius: 6px;
    padding:4px;
    background-color:#b5ffcc;
}

/* Top menu (keeps image and text on same line). Overriding here for clarity */

.top-bar-left {
    padding-top:5px!important;
    padding-bottom:5px!important;
}

.top-bar-left .menu li a {
    display: inline-block;
}

/* 
 * -----------------------------
 * thumbs up button on forms 
 * (hidden by default)
 * -----------------------------
 */

.submit-result {

    display:none;
    opacity: 0;

}

/* fadein when submit complete */

.submit-result-fadein {

    opacity: 1;
    display: block;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;

}

/* 
 * -----------------------------
 * keep sub-titles black, change on hover
 * TODO: use hover effects here
 * -----------------------------
 */

.grid-subtitle a {
    color:black;
}

.grid-subtitle a:hover {
    color:#2199e8;
}


/* Responsive Design */
$smaller: "only screen and (max-width:40em)";
$bigger: "only screen and (min-width:40.05em)";

/* colorize right menu, a <ul> */

#site-right-top-horizontal {

    background-color:$menu-background;
  
    li {

        border:1px solid #666;
    
        a {

            //default color
            text-shadow: 
                 0px 1px 2px rgba(0,0,0,0.9),
                 2px -2px 6px rgba(255,255,255,0.3);

        }

    }

    li:hover {
 
        a {
            
            color: #fff;
       
background: $menu-background; /* Old browsers */
            
background: -moz-linear-gradient(
    top, $menu-background 0%, $menu-highlight 41%, $menu-highlight 62%, $menu-background 100%
); /* FF3.6-15 */
            
background: -webkit-linear-gradient(
    top, $menu-background 0%,$menu-highlight 41%, $menu-highlight 62%, $menu-background 100%
); /* Chrome10-25,Safari5.1-6 */
            
background: linear-gradient(
    to bottom, $menu-background 0%,$menu-highlight 41%,$menu-highlight 62%,$menu-background 100%
); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            
filter: progid:DXImageTransform.Microsoft.gradient(
    startColorstr='$menu-background', endColorstr='$menu-highlight',GradientType=0
); /* IE6-9 */
            
        }
    }

    li:active {

        a {
            color: #777;
            background-color: #eee;
        }
    }

    @media #{$smaller} {
        
        li {
            
            border-bottom:1px solid #666;

        }

        // has to be removed independently of other styles

        li:last-child {
        
            border-radius:0!important;

        }
        
        border-radius:0;
        width: 100%!important; //NOTE: without this line, the responsive menu is NOT RE-ENTRANT!!!!
        
    }
    
    @media #{$bigger} {

        float:right;
        width: 595px!important; // has to be fixed, or it blows up to 100% 

    }

  }

/* the top menu bar, on non-home pages */

#site-top-bar {
    
    /* vertical menu */
    @media #{$smaller} {
        padding-left:0;
        padding-right:0;
    }

    /* horizontal menu */
    @media #{$bigger} {
        padding-right:7.5em!important; /* push away from rh side */
    }
    
}

/* horizontal counter on home page */
#home-horizontal-counter {
    
}

/* digital ecosystem widget */
#digital-ecosystem {

}

/* sticky footer (alsu uses js to make it sticky) */

footer {
  background: #333;
  color:white;
  padding: 20px;
}

/* 
 * -----------------------------
 * Specific widgets
 * -----------------------------
 */


/* panel on home page with 'plyojump' */

/* TODO - implement sunbeams in CSS, spinning, background fallback */
/* https://davidwalsh.name/css-spin-revisited  */

/* ---------- TOP MENU ---------------- */

/* top bar containing top menu */
.top-bar {
    background-color: #333;
}

/* name 'pete markiewicz' */
.top-bar-left {
    padding: 0.53em 0 0 0;
    font-size:18px;
}

.top-bar-left .menu {
    background-color: transparent; /* so name on left side doesn't have a color */
}

.top-bar-left li {
    padding:0;
}

.top-bar-left strong {
    font-weight: normal;
    color:#eee!important;
}

.top-bar-left img {
    position: relative;
    top: -3px;
    padding-right: 4px;
}

.top-bar-left img:hover {
    cursor:pointer;
}

/* actual 'pete markiewicz portfolio' text, remove padding from the left text */
.top-bar-left a {
    padding: 0.2em 0 0 0!important;
    color:#aaa;
}

/* color 'pete markiewicz' differently */
.top-bar-left a span {
    color: #eee;
}

/* pete markiewicz style */
.site-name {
    //TODO: PUT A TRANSITION HERE LIKE THE IMAGE HIGHLIGHTS
}

/* plyojump style */
.site-title {
    font-family: luckiest_guyregular!important;
    /*width:10em;*/
    /*width:100%;*/
    text-align:center;
    position:relative;
    top: 0.2em;
    font-size:7em;
    margin:auto;
}

/* 
 * plyojump style on smaller screens.
 * https://foundation.zurb.com/sites/docs/media-queries.html
 */

.site-title {
    
    @include breakpoint( medium only ) {

        font-size: 5.5em;

    }

    @include breakpoint( small only ) {

        font-size: 3em;

    }

}


/* the title row on secondary pages */
.rose {
    background-color: #fec6c7!important;
}

/* holds the callout on secondary pages */
.callout.large.primary {
    padding: 10px;
}

/* on secondary pages, reduce h1 */
.site-title.secondary {
    font-size: 3.0em;
    @include header-glow;
}

/* on secondary pages, reduce h2 */
.secondary.subheader {
    font-size: 1.0em;
}

.sticky-container h4, .sticky-container ul {
    background-color:#fff;
}

.sticky-container h4 {
    padding-left: 4px;
}

/* -------- MAIN HOME PAGE ----------- */

/* panel with page title on non-home pages */
.callout.primary {
    padding: 2em;
}

/* panel with home page image */
#home-callout-panel {

    padding: 0;

    background-color: #ff9e9e;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+18,ff9e9e+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, #ffffff 18%, #ff9e9e 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%,#ffffff 18%,#ff9e9e 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #ffffff 0%,#ffffff 18%,#ff9e9e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff9e9e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

/* panel below 3-parter, with tallies of websites, apps, etc */
#home-horizontal-counter {
    margin-bottom: 1em;
}

/* ------------ ABOUT PAGE ------------------ */

.about-links {
    color: #eee;
    background-color:#333;
}

.about-links a {
    color: #999!important;
}

/* ------------ COURSES PAGE ---------------- */

.course-group-list .callout {
    width: 95%;
}

/* list of courses in a particular category, e.g. design, ux */
.course-group-list li {
    padding-bottom: 1em;
}


/* ------------ PROJECT PAGE ---------------- */

/* projects-grid on projects page */
#projects-grid-wrapper {
    margin-bottom: 2em;
}

/* initially, these are shut. jQuery toggles them with a local style */
.projects-grid-description-short {
    display:none;
}

/* ------------- CONTACT FORM --------------- */

/* contact form (on multiple pages) */
#contact-form, #contact-event-form {
    padding: 1em;
}

/* ------------- PAGE FOOTER ---------------- */

// have to override here
#footer-menu img {
    width: 28px!important;
    height: 28px!important;
}

// force the menu icon hyperlink open at all sizes
#menu-icon-li a {
    width: 28px!important;
}

// push credit down and to the right.
#footer-site-credit p {
    text-align: right;
    padding-top: 0.8em;
}


/* ------------ Google Calendar ------------- */

/* http://www.webdesignerdepot.com/2012/04/integrating-google-calendar-with-your-website/ */

.blog-post iframe {

}

.view-cap {

}

.view-container-border {
    width: 100%;
}

.mv-dayname {

}

.st-bg {

}

.st-dtitle {

}

.st-dtitle-today {
}

.st-dtitle-next {

}
