/* CSS for project pages */

/* titles for individual steps in project */

.h4-process-title {
	color: #6c6cdd; 
}

.project-right-callout {
	width: 40%;
	margin: 1em 0 0 1em;
	padding: 1em;
	border-radius: 1em;
}


/* add space between project step image and description */
.project-steps-img {
	padding-bottom: 1em;
}

.project-caption {
	margin-top:0.5em;
	background-color:white;
	padding: 1em;
	border: 1px solid #ddd;
	text-align:center;
}

/* enclosing box for caption */
.project-caption-grey {
	margin-top:0.5em;
	background-color:#f0f0f0;
	padding: 1em;
	border: 1px solid #ddd;
	text-align:center;
}

/* enclosing box for link */
.project-caption-link {
	margin-top:0.5em;
	margin-bottom:0.5em;
	padding: 1em;
	font-size: 120%;
	background-color: #f3f3f3;
}

/* enclosing box for video */
.project-center-video {
	display: flex;
	align-items: center;
	justify-content: center;
}
